// Dynamically load the gallery image into a reveal popup
	$('.gallery__item a').on('click', function (e) {
		e.preventDefault();

		var $clickedThumbnail = $(this);

		var imagelink = $(this).attr('href');
		var titletext = $(this).data('title');
		var descriptiontext = $(this).data('description');
		var booklink = $(this).data('booklink');
		var booktext = $(this).data('booktext');

		var modal = '<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><button class="gallery-modal__prev">Previous</button><button class="gallery-modal__next">Next</button> <div class="gallery-modal__image row"><div class="image-wrapper columns large-8"><img src="' + imagelink + '"/></div><div class="pop-up-content columns large-4"><h2>' + titletext + '</h2><div class="description">' + descriptiontext + '</div>';

		if ((typeof booklink !== "undefined")&&(booklink !== "")) {
			modal += '<a class="booklink" href="' + booklink + '">' + booktext + '</a>';
			}

			modal += '</div></div>';


		var $modal = $(modal);

        $('body').append($modal);

        $('#gallery-modal').foundation();
        /* $(document).foundation('equalizer', 'reflow');
			$(window).trigger('resize');
			setTimeout(function () {
		    	$(window).trigger('resize');
			}, 500); */
        $modal.foundation('reveal', 'open', function() {
			//force equalizer to fire AFTER modal has finished opening
			//$(document).foundation('equalizer', 'reflow');
			$(window).trigger('resize');
			setTimeout(function () {
		    	$(window).trigger('resize');
			}, 500);
  		});


        /* $modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        }); */


        $('.gallery-modal__close').on('click', function () {
	        $modal.foundation('reveal', 'close');
	        $modal.remove();
        });



         $('.gallery-modal__next').on('click', function () {
	        $modal.foundation('reveal', 'close');
	        if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
		        $clickedThumbnail.parent().next(':visible').find('a').trigger('click');
		        $(document).foundation('equalizer', 'reflow');
				$(window).trigger('resize');
				setTimeout(function () {
			    	$(window).trigger('resize');
				}, 500);
	        } else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
		       // $clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
		       $modal.foundation('reveal', 'close');
	        }
        });

        $('.gallery-modal__prev').on('click', function () {
	        $modal.foundation('reveal', 'close');
	        if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
		        $clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
		        $(document).foundation('equalizer', 'reflow');
				$(window).trigger('resize');
				setTimeout(function () {
			    	$(window).trigger('resize');
				}, 500);
	        } else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
		        // $clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
		        $modal.foundation('reveal', 'close');
	        }
        });
        return false;
	});




	// Filter the gallery items
	var $_filterButtons = $('.gallery__filter-buttons button');
	var $_filterItems = $('.gallery__item');

    $_filterButtons.on('click', function() {

	    if ($(this).hasClass('all')) {
			$('.gallery__filter-buttons button.on').removeClass('on');
			$(this).addClass('on');
			$_filterItems.fadeIn();
			//refreshMasonry();
		    return;
	    }

	    if ($(this).hasClass('on')) {
		    $(this).removeClass('on');
	    } else {
		    $('.gallery__filter-buttons button.on').removeClass('on');
		    $(this).addClass('on');
	    }

        var currentCats = '';

        // Loop through the "on" buttons and build a class selector string of the active
        $_filterButtons.each(function() {
            if ($(this).hasClass('on')) {
                currentCats += '.' + $(this).data('category') + ', ';
            }
        });
        currentCats = currentCats.substr(0, currentCats.length - 2); // Remove the last comma and space from the string, otherwise jQuery borks

        // No categories selected so show them all
        if (currentCats == '') {
			$_filterItems.fadeIn();
			//refreshMasonry();
            return;
        }

        // Hide or show the elements that match the class
        $_filterItems.each(function() {
            if ($(this).is(currentCats)) {
                $(this).fadeIn();
            } else {
                $(this).fadeOut();
            }
		});


		//refreshMasonry();

		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);


	});

	// set masonry layout for visible items

	var $grid = $('.gallery__grid').masonry({
		columnWidth: '.gallery__item-sizer',
		itemSelector: '.gallery__item',
		percentPosition: true,
		horizontalOrder: true,
		fitWidth: true
	});


	// layout Masonry after each image loads
	$grid.imagesLoaded().progress( function() {
	  $grid.masonry('layout');
	});

	var refreshMasonry = function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);
	};

	$('#load').click(function() {
		$('.gallery__grid .gallery__item').not(':visible').slice(0, 10).slideDown();
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);

	});


	$('button.all').click(function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);

	});


	$('.gallery__filter-buttons .arrow-down').click(function() {
		$('.gallery__filter-buttons button').show();
		$('.gallery__filter-buttons .arrow-up').show();
		$(this).hide();
	});

	$('.gallery__filter-buttons .arrow-up').click(function() {
		$('.gallery__filter-buttons button').hide();
		$('.gallery__filter-buttons button.on').show();
		$('.gallery__filter-buttons .arrow-down').show();
		$(this).hide();
	});


	$( window ).resize(function() {
		setTimeout(function () {
		    $('.gallery__grid').masonry();
		}, 500);
	});


    // Pre-filter the offers
    // Get the url parameters
    var urlParams = {};
    var e,
        a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g,
        d = function(s) {
            return decodeURIComponent(s.replace(a, ' '));
        },
        q = window.location.search.substring(1);

    while ((e = r.exec(q))) urlParams[d(e[1])] = d(e[2]);

    if (urlParams['category']) {
        $('.gallery__filter-buttons button[data-category="' + urlParams['category'] + '"]').trigger('click');
    }
