$(document).ready(function() {
    // Common functions for all Iconic hotel sites go here
    // (i.e. anything new following the CMS merge)

   // initVideoBar();

    function initVideoBar() {

    var iframePlayer = $('iframe#desktop-video');
    var player = new Vimeo.Player(iframePlayer);

          $('.video-bar').css('display', 'block');
          $('#volumeSwitch').toggleClass('off');
          $('#volumeOn').css('visibility', 'hidden');
          $('#volumeOff').css('visibility', 'visible');
          $("#volumeSwitch").prop('checked', true);

    $('.video-bar input[type="checkbox"]').on('change', function() {
      player.getVolume().then(function(volume) {
        if ( volume == 0  ) {
            player.setVolume(0.66);
            $('#volumeSwitch').toggleClass('on');
            $('#volumeSwitch').toggleClass('off');
            $('#volumeOff').css('visibility', 'hidden');
            $('#volumeOn').css('visibility', 'visible');
            $("#volumeSwitch").prop('checked', false);
        } else {
            player.setVolume(0);
            $('#volumeSwitch').toggleClass('on');
            $('#volumeSwitch').toggleClass('off');
            $('#volumeOff').css('visibility', 'visible');
            $('#volumeOn').css('visibility', 'hidden');
            $("#volumeSwitch").prop('checked', true);
        }
    })
})
}

});
