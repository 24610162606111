$(document).ready(function() {
        initBookingWidgetReveal();
});

function initBookingWidgetReveal() {
    // remove default click handlers for book now button and re-attach the reveal
    $('div.book-now a, div.mobile-header-book a').off('click');
    $(document).foundation('reveal', 'reflow');

    // initialise tabs
    $(document).foundation('tab', 'reflow');

    // blur background when opening widget
    $('.booking-widget-reveal')
        .on('open.fndtn.reveal', function() {
            $('.off-canvas-wrap').addClass('off-canvas-wrap--blurred');
        })
        .on('closed.fndtn.reveal', function() {
            $('.off-canvas-wrap').removeClass('off-canvas-wrap--blurred');
        });
        
     $('.booking-widget-reveal .tab-title.active a').trigger('click');

    // open link in browser from tab buttons with external links
    $('.booking-widget-reveal .tab-title a[href^="http"], .booking-widget-reveal .tab-title a[href^="/"]').on('click', function() {
        window.open($(this).attr('href'), $(this).attr('target'));

        return false;
    });

    // set up date pickers
    var now = new Date();
    
    // Temp pre-opening date for Cadogan only
    if ( ($('body').hasClass('cadogan')) && (now < new Date('2021-04-12')) ) {
        now = new Date('2021-04-12');
    }
    
    console.log(now);
    
    var today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
    var yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    var tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    $('.booking-widget-room__input-box--date input')
        .fdatepicker({
            format: 'yyyy-MM-dd',
            weekStart: 1,
            startDate: yesterday,
            onRender: function(date) {
                if (this.element.attr('name') === 'depart') {
                    // add extra class to the start date
                    var preStartDate = new Date(this.startDate);
                    preStartDate.setDate(preStartDate.getDate() - 1);

                    if (date.valueOf() === preStartDate.valueOf()) {
                        return 'start';
                    }
                }

                return '';
            }
        })
        .on('show', function(e) {
            var datepicker = $('.datepicker:visible');

            // add extra CSS class for different styling
            datepicker.addClass('datepicker--light');

            var box = $(this).closest('.booking-widget-room__input-box');

            // add extra CSS class for hover handling
            datepicker.addClass('datepicker--' + box.find('input').attr('name'));

            // always position it below the date box
            var dateBoxOffset = box.get(0).getBoundingClientRect();

            var datepickerElem = datepicker.get(0);

            datepickerElem.style.setProperty('top', dateBoxOffset.bottom + $(window).scrollTop() + 'px', 'important');
            datepickerElem.style.setProperty('left', dateBoxOffset.left + $(window).scrollLeft() + 'px', 'important');
        })
        .on('changeDate', function(e) {
            // update display date
            if (e.date) {
                var date = new Date(e.date);

                $(this)
                    .siblings('.booking-widget-room__input-month')
                    .text(date.toLocaleDateString('en-GB', { month: 'long', timeZone: 'UTC' }));
                $(this)
                    .siblings('.booking-widget-room__input-day')
                    .text(date.getUTCDate());

                $(this)
                    .closest('.booking-widget-room__input-box')
                    .addClass('booking-widget-room__input-box--selected');
            } else {
                $(this)
                    .siblings('.booking-widget-room__input-month')
                    .text('');
                $(this)
                    .siblings('.booking-widget-room__input-day')
                    .text('');

                $(this)
                    .closest('.booking-widget-room__input-box')
                    .removeClass('booking-widget-room__input-box--selected');
            }
        });

    var arrive = $('.booking-widget-room__input-box--date input[name="arrive"]');
    var depart = $('.booking-widget-room__input-box--date input[name="depart"]');

    arrive.fdatepicker('update', today).trigger({ type: 'changeDate', date: today.toUTCString() });

    depart.data('datepicker').startDate = tomorrow;
    depart.fdatepicker('update', null).trigger({ type: 'changeDate', date: null });

    arrive.on('changeDate', function(e) {
        // update the minimum date of departure
        var date = new Date(e.date);
        date.setDate(date.getDate() + 1);

        depart.data('datepicker').startDate = date;
        depart.fdatepicker('update', '').trigger({ type: 'changeDate', date: null });

        // open the departure date picker
        arrive.fdatepicker('hide');
        depart.fdatepicker('show');
    });

    // add date picker hover
    $('.datepicker-dropdown .datepicker-days tbody')
        .on('mouseenter', 'td', function() {
            var cell = $(this);
            cell.addClass('hover');

            if (!cell.closest('.datepicker-dropdown').hasClass('datepicker--depart')) {
                // only do the next steps for departure calendar
                return;
            }

            // add highlight class to previous cells
            var cells = cell.closest('tbody').find('td');
            var hoverCellIndex = cells.index(cell);
            var hasStartCell = cells.is('.start');
            var passedStartCell = false;

            cells.each(function(i, el) {
                var c = $(el);

                if (i > hoverCellIndex) {
                    // reached our hover cell, so skip anything after that
                    return false;
                }

                if (!hasStartCell || passedStartCell) {
                    // add highlight class to cells if we have passed the start date
                    c.addClass('highlight');
                }

                if (!passedStartCell) {
                    // check if current cell is start date
                    passedStartCell = c.hasClass('start');
                }
            });
        })
        .on('mouseleave', 'td', function() {
            // remove hover/highlight classes
            var cell = $(this);
            cell.removeClass('hover');
            cell.closest('tbody')
                .find('td')
                .removeClass('highlight');
        });

    //var viewDate = depart.data('datepicker').viewDate;
    //var day = parseInt(cell.text());

    // setup table booking toggle
    $('.booking-widget-table__option button').on('click', function() {
        $(this)
            .closest('.booking-widget-table__option')
            .siblings('.booking-widget-table__option-iframe')
            .toggleClass('booking-widget-table__option-iframe--active');
    });
}
