$(document).ready(function () {
    ajaxifyLoginForm();
});

function ajaxifyLoginForm() {
    var options = {
        target: '#favourites-login-container',   // target element(s) to be updated with server response 
        success: showLoginResponse
    };

    $('#login-form').ajaxForm(options);
}

function showLoginResponse(responseText, statusText, xhr, $form) {
    // for normal html responses, the first argument to the success callback 

    var reload = $(responseText).find("#reload-page").val();
    if (reload == "true") {
        setTimeout("location.reload(true);", 500);
    }
    else {
        ajaxifyLoginForm();
    }


}

function addFavouriteToList(id, url, bookingLink, name, elem) {
    var status = false;
    if (typeof angular != 'undefined') {
        var scope = angular.element(document.getElementById("favourites-panel")).scope();
        for(i=0;i<2;i++) {
            $(elem).fadeTo('slow', 0.5).fadeTo('slow', 1.0);
        }
        scope.$apply(function () {
            status = scope.addFavouriteToList(id, url, bookingLink, name);
        });
    }
    else {
        $("#favourites-login").show();
    }

    return false;
}

function removeModelStateMessage(button) {
    if ($("#login-form").valid()) {
        $(button).siblings('.validation-summary-errors').remove();
        $(button).before("<div class='validation-summary-errors'><ul><li>Logging in...</li></ul></div>");
    }
    return true;
}


